import { Link, useNavigate } from "react-router-dom";
import checker from "../../assets/checker.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import { imageLink } from "../blog/Index";

interface BlogsProps {
  data: any;
}

interface CardProps {
  title?: string;
  desc?: string;
  id: string;
  src?: string;
}

const Blogs = ({ data }: BlogsProps) => {
  const wide = window.innerWidth;

  const Card = ({ desc, title, id, src }: CardProps) => {
    const navigate = useNavigate();

    return (
      <>
        <div
          className="relative w-72 h-80 cursor-pointer"
          onClick={() => {
            navigate(`/blog/${id}`);
          }}
        >
          <div className="bg-[#314256] w-48 h-72 absolute z-20 bottom-0 right-24 text-[#CADFFB] py-12 flex flex-col justify-between items-center">
            <p className="text-base px-4 text-center">{title}</p>
            <p className="text-sm px-4 text-center">
              {desc?.split("").splice(0, 110).join("") + "..."}
            </p>
          </div>
          <div className="absolute w-48 h-72 right-0 top-0 z-10">
            <img src={src} className="w-48 h-72 object-cover" alt="" />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        id="blog"
        className="bg-[#cadffb] flex flex-col items-center gap-y-8 py-8 overflow-hidden"
      >
        <div className="flex flex-col md:flex-row self-start md:items-center px-6 gap-y-4 overflow-hidden">
          <p className="md:-rotate-90 text-4xl md:text-7xl text-white tracking-wide ml-0 md:ml-12 lg:ml-20">
            Blogs
          </p>
          <div className="flex pl-0 " style={{ width: "fit-content" }}>
            <Splide
              options={{
                arrows: wide < 800 ? true : false,
                pagination: false,
                perPage: wide < 800 ? 1 : 4,
                gap: wide < 800 ? 20 : 250,
                drag: wide < 800 ? true : false,
                padding: "0",
                cover: true,
                width: "calc(100vw - 3rem)",
                type: wide < 800 ? "loop" : "",
              }}
            >
              {data.slice(0, 4).map((step: any, index: number) => {
                return (
                  <SplideSlide key={index}>
                    <div style={{ width: "100%" }}>
                      <Card
                        title={step.title}
                        desc={step.description}
                        id={step.slug}
                        src={step.image}
                      />
                    </div>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
        <Link
          to={"/blog"}
          className="border border-[#314256] px-4 py-2 text-[#314256] text-lg"
        >
          View all Blogs
        </Link>
      </div>
    </>
  );
};

export default Blogs;
